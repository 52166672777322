import React, {
  useState,
  useEffect,
  useContext,
  useRouter,
  useCallback,
  useReducer,
  useRef,
} from "react";
//import { getFunctions, httpsCallable } from "firebase/functions";
import { useLocalStorage } from "react-use";
// import { db, auth, storage, db2, auth2, storage2 } from "../database/Firebase";
// import { collection, doc, setDoc, getDoc, getDocs } from "firebase/firestore";

import { db2 } from "../database/Firebase";

import {
  collection,
  doc,
  updateDoc,
  getDocs,
  addDoc,
  query as fsquery,
  where,
} from "firebase/firestore";
import countries from "../lib/countries";
//import { useStripe } from '@stripe/react-stripe-js';
//import Stripe from 'stripe';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import { PaymentElement } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import axios from "axios";
const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST;
const stripePromise = loadStripe(publishableKey);

//console.log('process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
//console.log('publishableKey', publishableKey);
//console.log('xxxxx');

const Checkout = ({ cartdata }) => {
  const LOCAL_STORAGE_KEY = "DJ_OBAH_SHOP";

  const INITIAL_STATE = [
    // initial state
  ];

  const reducer = (state, action) => {
    let update = [];

    switch (action.type) {
      case "ADD":
        let i = state.findIndex((item) => item.id === action.id);

        if (i == -1) {
          state.push(action);
          update = state;
        } else {
          // check the details of item before updating, it might still be unique
          if (action.size == state[i].size && action.color == state[i].color) {
            let qty = 0;
            qty = parseInt(state[i].quantity);
            qty = qty + parseInt(action.quantity);
            state[i] = action;
            state[i].quantity = qty;
          } else {
            state.push(action);
          }
          update = state;
        }

        break;
      case "REDUCE":
        // code block
        break;
      case "DELETE":
        // code block
        break;
      default:
      // code block
    }
    // return updated state based on `action.type`
    return update;
  };

  const usePersistReducer = () => {
    // grab saved value from `localStorage` and
    // a function to update it. if
    // no value is retrieved, use `INITIAL_STATE`
    const [savedState, saveState] = useLocalStorage(
      LOCAL_STORAGE_KEY,
      INITIAL_STATE
    );

    // wrap `reducer` with a memoized function that
    // syncs the `newState` to `localStorage` before
    // returning `newState`. memoizing is important!
    const reducerLocalStorage = useCallback(
      (state, action) => {
        const newState = reducer(state, action);

        saveState(newState);

        return newState;
      },
      [saveState]
    );

    // use wrapped reducer and the saved value from
    // `localStorage` as params to `useReducer`.
    // this will return `[state, dispatch]`
    return useReducer(reducerLocalStorage, savedState);
  };

  const [state, dispatch] = usePersistReducer();
  const [cart_quantity, setCart_quantity] = useState(0);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [releaseData, setReleaseData] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [active, setActive] = useState(false);
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("DJ_OBAH_SHOP"))
  );

  const [stated, setState] = React.useState({ value: 10 });
  const [total, setTotal] = useState(0);
  const [cartImage, setCartImage] = useState([]);
  const [country, setCountry] = useState(null);
  const [shipping, setShipping] = useState(0);
  const [shippingto, setShippingto] = useState(null);
  const [shippingTotal, setShippingTotal] = useState(0);

  const [stripePromise2, setStripePromise2] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  const [email, setEmail] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [zipPostalCode, setZipPostalCode] = useState("");
  const [shippingCountry, setShippingCountry] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [countryCheck, setCountryCheck] = useState(null);
  const [currentY, setCurrentY] = useState(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [newOrderId, setNewOrderId] = useState(null);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const [mouseCoordinates, setMouseCoordinates] = useState({ x: 0, y: 0 });

  const mouseMoveHandler = (event) => {
    setMouseCoordinates({
      x: event.clientX,
      y: event.clientY,
    });
  };

  useEffect(() => {
    window.addEventListener("mousemove", mouseMoveHandler);
    return () => {
      window.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, []);

  useEffect(() => {
    if (shippingto !== null) {
      //console.log("hit it");
      handlePayCart({});
    }
  }, [shippingto]);

  function forceUpdate2() {
    setState((prev) => {
      return { ...prev };
    });
  }

  // const getItemImagesx = async (item) => {
  //   const ref = collection(db2, "users/Bs7gqBrNUtMBmM7jgEyGmCKtSNl1/ecommerce");
  //   const q = fsquery(ref, where("product_id", "==", item.id));
  //   const querySnapshot = await getDocs(q);
  //   querySnapshot.forEach((doc, index) => {
  //     setCartImage((oldData) => [...oldData, doc.data().imageList[0].url]);
  //   });
  //   forceUpdate2();
  // };

  const getItemImages = useCallback(async (item) => {
    const ref = collection(db2, "users/Bs7gqBrNUtMBmM7jgEyGmCKtSNl1/ecommerce");
    const q = fsquery(ref, where("product_id", "==", item.id));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc, index) => {
      setCartImage((oldData) => [...oldData, doc.data().imageList[0].url]);
    });
    forceUpdate2();
  }, []);

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 767px)"); // Adjust the breakpoint as needed
    const handleMobileChange = (event) => {
      setIsMobile(event.matches);
    };

    mobileMediaQuery.addEventListener("change", handleMobileChange);
    setIsMobile(mobileMediaQuery.matches);

    return () => {
      mobileMediaQuery.removeEventListener("change", handleMobileChange);
    };
  }, []);

  useEffect(() => {
    const gotData = async () => {
      if (country === "GB") setShipping(5);
      else setShipping(12);
      setTotal(
        cart.reduce((count, item, index) => {
          getItemImages(item);
          let q = quantity;
          q[index] = item.quantity;
          setQuantity(q);
          return count + item.quantity * (item.price + shipping);
        }, 0)
      );
      setShippingTotal(
        cart.reduce((count, item, index) => {
          return count + item.quantity * shipping;
        }, 0)
      );
    };
    gotData();
  }, [cart, country, getItemImages, quantity, shipping]);

  useEffect(() => {
    const gotData = async () => {
      setCart(JSON.parse(localStorage.getItem("DJ_OBAH_SHOP")));
    };
    gotData();
  }, [cartdata]);

  useEffect(() => {
    if (isMobile) {
      refocusInputRef.current.blur();
      forceUpdate2();
    }
  }, [country, isMobile]);

  const handleQuantity = (q, i, item) => {
    if (q > 0) {
      cart[i].quantity = parseInt(q);
      localStorage.setItem("DJ_OBAH_SHOP", JSON.stringify(cart));
      setCart(JSON.parse(localStorage.getItem("DJ_OBAH_SHOP")));
      //addProductToCart(item);
    } else {
      cart.splice(i, 1);
      localStorage.setItem("DJ_OBAH_SHOP", JSON.stringify(cart));
      //removeProductFromCart(item.id);
    }
  };

  const handlePayCart2 = async () => {
    setShowCheckout(!showCheckout);
  };

  const handlePayCart = async () => {
    //console.log("currentCart", cart, cartImage);
    //console.log("total", total);
    //console.log("shippingTotal", shippingTotal);
    // setMessage(JSON.stringify({ checkout: true }));
    const items = cart.map((item) => {
      return {
        prod_id: item.id,
        name: item.title,
        price: item.price * 100,
        images: item.image,
        quantity: item.quantity,
        desc: item.desc,
        color: item.color,
        size: item.size,
      };
    });

    //console.log("items", items);
    doAddNewCustomerData(items);
  };

  const doAddNewCustomerData = async (data) => {
    const items = data;
    // add address to order
    const address = {
      email,
      customerName,
      address1,
      address2,
      city,
      stateProvince,
      zipPostalCode,
      shippingto,
      telephoneNumber,
    };
    console.log("data", data);
    try {
      const newprod = await addDoc(
        collection(db2, `users/${process.env.REACT_APP_SITEID}/orders`),
        {
          productData: data || null,
          //details: address,
          total: total  || null,
          shippingTotal: shippingTotal || null,
          email: email || null,
          orderemailsent: false,
        }
      );

      setNewOrderId(newprod.id);

      // custom stripe payform intent ////////////

      // fetch("https://us-central1-thisisobah-dj.cloudfunctions.net/createPaymentIntent", {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({
      //     items: items,
      //     shippingTotal: shippingTotal,
      //     shippingName: "to " + shippingto,
      //     clientId: newprod.id,
      //   }),
      // })
      //   .then((res) => res.json())
      //   .then((data) => {
      //     console.log('data', data)
      //     setClientSecret(data.clientSecret)
      //     setShowCheckout(!showCheckout)
      //   });

      try {
        // stripe checkout screen
        const checkoutIntent = await axios.post(
          "https://us-central1-thisisobah-dj.cloudfunctions.net/createPaymentIntent",
          {
            items: items,
            shippingTotal: shippingTotal,
            shippingName: "to " + shippingto,
            //clientId: newprod.id,
          }
        );

        //const data = await checkoutIntent;
        console.log("new order id", newprod.id);
        console.log("checkoutIntent", checkoutIntent);

        setClientSecret(checkoutIntent.data.clientSecret);

        const ref = doc(
          db2,
          `users/${process.env.REACT_APP_SITEID}/orders`,
          newprod.id
        );
        updateDoc(ref, {
          id: newprod.id,
          clientId: checkoutIntent.data.clientSecret,
        });

        setShowCheckout(!showCheckout);

        /**
         * https://thisisobah.com/success/?payment_intent=pi_3OZGuYCKUVL8kNyE0E6pcwuZ&payment_intent_client_secret=pi_3OZGuYCKUVL8kNyE0E6pcwuZ_secret_ZECDUTOy4n38kConVGKWrvTXP&redirect_status=succeeded
         */

        if (checkoutIntent.error) {
          alert(checkoutIntent.error.message);
        }
      } catch (error) {
        console.log(error);
      }

      // end custom stripe payform intent ////////

      // redirected stripe payform ////////////////////////////////////
      // try {
      //   // stripe checkout screen
      //   const stripe = await stripePromise;
      //   const checkoutSession = await axios.post("https://us-central1-thisisobah-dj.cloudfunctions.net/createCheckoutSession", {
      //     items: items,
      //     shippingTotal: shippingTotal,
      //     shippingName: "to " + shippingto,
      //     clientId: newprod.id,
      //   });
      //   const result = await stripe.redirectToCheckout({
      //     sessionId: checkoutSession.data.id,
      //   });
      //   if (result.error) {
      //     alert(result.error.message);
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
      // end redirected stripe pay form ////////////////////////////
    } catch (error) {
      console.log(error);
    }
  };

  const pickCountry = (e, c) => {
    setCountry(c);

    if (c === "GB") setShipping(5);
    else setShipping(12);
    //console.log("c:" + c);

    if (isMobile) {
      //setTimeout(() => {
      //console.log('pick Country shipping to ', country)
      //}, 1000);
      refocusInputRef.current.blur();
      setShowDropDown(false);
      //setShowDropDown(!showDropDown);
    }
  };

  const filterCountryList = () => {
    let input, filter, dropdownContent, ali, tc, i, txtValue;
    input = document.getElementById("filterInput");
    filter = input.value.toUpperCase();
    dropdownContent = document.getElementById("dropdown-content");
    ali = dropdownContent.getElementsByTagName("a");
    for (i = 0; i < ali.length; i++) {
      tc = ali[i];
      txtValue = tc.textContent || tc.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        ali[i].style.display = "";
      } else {
        ali[i].style.display = "none";
      }
    }
  };

  // useEffect(() => {
  //   console.log('countryCheck updated!')
  //   const intervalId = countryCheck && setInterval(() => {
  //     if (currentY == mouseCoordinates.y) {
  //       // hasnt moved screen
  //       console.log('y not moved', mouseCoordinates.y)
  //     } else {
  //       console.log('y moved', mouseCoordinates.y)
  //       const clickEvent = new Event('touchStart', {"bubbles":true})
  //       document.dispatchEvent(clickEvent);
  //       document.dispatchEvent(clickEvent);
  //       setCountryCheck(null)
  //       clearInterval(intervalId)
  //     }
  //   }, 100);
  //   return () => clearInterval(intervalId)

  // },[countryCheck, mouseCoordinates]);

  const refocusInputRef = useRef(null);

  // const mobileLoseFocus = (c) => {
  //   //check if it is mobile. if it is lose focus on shipping-trigger
  //   console.log('isMobile:', isMobile)
  //   if (isMobile) {
  //     //refocusInputRef.current.click();
  //     // get mouse y coord
  //     setCurrentY(mouseCoordinates.y)
  //     console.log('mouseCoordinates.y :', mouseCoordinates.y)
  //     console.log('country', country)
  //     setCountryCheck(c)
  //     setTimeout(() => {
  //       //   setCountryCheck(null)
  //       //setCountryCheck(country)
  //     }, 1000);
  //   }
  // }

  const mobileDropContent = () => {
    //console.log('mobileDropContent isMobile:', isMobile)
    if (isMobile) {
      //console.log('dropdown the content')
      setShowDropDown(!showDropDown);
      //refocusInputRef.current.focus();
    }
  };

  return (
    <>
      <section className="p-4 mt-12 md:p-20 lg:p-20 md:mt-10 lg:mt-10 pb-40">
        <div className="container mx-auto -mt-24 w-full px-4   bg-transparent relative">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
            <div className="bg-white mb-8 p-8 shadow-lg col-span-2">
              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-10 lg:grid-cols-10 gap-3 flex items-center releaseSquare lg:pt-[32px] lg:pr-[32px] lg:pl-[32px] font-black">
                <div className="font-black col-span-1 hidden lg:block"></div>
                <div className="font-black col-span-3 hidden lg:block"></div>
                <div className="col-span-2 hidden lg:block">color</div>
                <div className="col-span-2 hidden lg:block">size</div>
                <div className="custom-number-input w-16 hidden lg:block">
                  qty
                </div>
                <div className=" hidden lg:block text-right">price</div>
              </div>
              {cart.map((item, index) => (
                <div
                  className="releaseSquare p-[16px]  lg:p-[32px] "
                  key={index}
                >
                  <div className="releaseSquare--info text-left w-full">
                    <div className="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-10 lg:grid-cols-10 gap-3 flex items-center">
                      <div className="font-black col-span-3 lg:col-span-1 content-center">
                        <img src={item.image} className="w-full" />
                      </div>
                      <div className="font-black col-span-3 lg:col-span-3">
                        {item.title}
                      </div>
                      <div className="col-span-3 lg:col-span-2">
                        color: {item.color}
                      </div>
                      <div className="col-span-3 lg:col-span-2">
                        size: {item.size}
                      </div>
                      <div className="custom-number-input spinners-container w-16 col-span-2 lg:col-span-1">
                        <input
                          type="number"
                          className="outline-none focus:outline-none text-center w-20 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                          name="custom-input-number"
                          id="quantity"
                          min="0"
                          value={quantity[index]}
                          onChange={(e) => (
                            handleQuantity(e.target.value, index, item),
                            forceUpdate()
                          )}
                        />

                        <div className="spinners">
                          <button
                            onClick={(e) => (
                              handleQuantity(quantity[index] + 1, index, item),
                              forceUpdate()
                            )}
                            className="spinner increment"
                          >
                            &#9650;
                          </button>
                          <button
                            onClick={(e) => (
                              handleQuantity(quantity[index] - 1, index, item),
                              forceUpdate()
                            )}
                            className="spinner decrement"
                          >
                            &#9660;
                          </button>
                        </div>
                      </div>
                      <div className="text-right col-span-1 lg:col-span-1">
                        £{item.price * item.quantity}
                        {/* <CurrencyFormat
                          value={item.price * item.quantity}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"£"}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="releaseSquare  lg:p-[32px] lg:pt-[0px] ">
                <div className="releaseSquare--info text-left w-full">
                  <hr />
                  <div className="grid grid-cols-10 sm:grid-cols-10 md:grid-cols-10 lg:grid-cols-10 gap-3 p-[16px] lg:p-[0]">
                    <div className="col-span-9 lg:col-span-9 text-right">
                      shipping
                    </div>
                    <div className="col-span-1 lg:col-span-1 text-right lg:text-right">
                      £{shippingTotal}
                      {/* <CurrencyFormat
                        value={shippingTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"£"}
                      /> */}
                    </div>
                    <div className="col-span-9 lg:col-span-9 text-right">
                      total
                    </div>
                    <div className="col-span-1 lg:col-span-1 text-right  lg:text-right">
                      £{total}
                    </div>
                    {/* <CurrencyFormat
                      className="font-black"
                      value={total}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"£"}
                    /> */}
                  </div>
                  <hr />
                  <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-9 lg:grid-cols-9 gap-3 mt-8 mb-8">
                    <div className="col-span-7">
                      <div className="relative mx-auto max-w-screen-xl px-4">
                        <div className="">
                          {/* <!-- new dropdown --> */}

                          {isMobile ? (
                            <div onClick={(e) => mobileDropContent()}>
                              <a
                                href="#"
                                id="shipping-trigger"
                                className="btn btn-l btn-gradient dropbtn"
                              >
                                <span className="btn-border"></span>
                                <span className="btn-hover"></span>
                                <span className="btn-caption shipping">
                                  Shipping to {shippingto}
                                </span>
                              </a>

                              <div
                                id="dropdown-content"
                                className={`country-modal ${
                                  showDropDown ? "show-dropdown-menu" : ""
                                }`}
                              >
                                <div
                                  ref={refocusInputRef}
                                  type="text"
                                  id="filterInput"
                                  className="mobileFilterinput"
                                >
                                  pick country to ship to
                                </div>

                                {countries.map((item, index) => (
                                  <a
                                    className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                                    onClick={(e) => (
                                      pickCountry(e, item.value),
                                      setShippingto(item.title),
                                      mobileDropContent()
                                    )}
                                  >
                                    {item.title}
                                  </a>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <div className="dropdown">
                              {/* <button className="dropbtn">Dropdown</button> */}

                              <a
                                href="#"
                                id="shipping-trigger"
                                className="btn btn-l btn-gradient dropbtn"
                              >
                                <span className="btn-border"></span>
                                <span className="btn-hover"></span>
                                <span className="btn-caption">
                                  Shipping to {shippingto}
                                </span>
                              </a>

                              <div
                                id="dropdown-content"
                                className={`dropdown-content ${
                                  showDropDown ? "show-dropdown-menu" : ""
                                }`}
                              >
                                <form class="form notify-form form-light">
                                  <input
                                    ref={refocusInputRef}
                                    type="text"
                                    id="filterInput"
                                    onKeyUp={() => filterCountryList()}
                                    placeholder="Search for names.."
                                    title="Type in a name"
                                  />
                                </form>

                                {countries.map((item, index) => (
                                  <a
                                    className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                                    onClick={(e) => (
                                      pickCountry(e, item.value),
                                      setShippingto(item.title),
                                      mobileDropContent()
                                      //setTimeout(() => {
                                      //mobileLoseFocus(item.value)
                                      //}, 1000)
                                    )}
                                  >
                                    {item.title}
                                  </a>
                                ))}
                              </div>
                            </div>
                          )}

                          {/* {(showCheckout && !isMobile) && <div>checkout</div>} */}
                          {showCheckout && !isMobile && (
                            <Elements stripe={stripePromise} options={options}>
                              <CheckoutForm newOrderId={newOrderId} />
                            </Elements>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white mb-8 shadow-lg col-span-2 lg:col-span-1">
              {shippingto && (
                <div className="releaseSquare  lg:p-[32px]">
                  {!showCheckout && (
                    <>
                      <h1>PAYMENT FORM LOADING</h1>
                      <div>please wait...</div>
                    </>
                  )}

                  {/* <div className="w-full md:w-96 md:max-w-full mx-auto checkout-styles">
                    <div className="p-6 ">
                      <form class="form notify-form form-light">
                        <input
                          name="email"
                          type="email"
                          className=""
                          placeholder="Your Email Address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />

                        <input
                          name="name"
                          type="text"
                          className=""
                          placeholder="Your name"
                          value={customerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                        />

                        <input
                          name="address1"
                          type="text"
                          className=""
                          placeholder="Address line 1"
                          value={address1}
                          onChange={(e) => setAddress1(e.target.value)}
                        />

                        <input
                          name="address2"
                          type="text"
                          className=""
                          placeholder="Address line 2"
                          value={address2}
                          onChange={(e) => setAddress2(e.target.value)}
                        />

                        <input
                          name="city"
                          type="text"
                          className=""
                          placeholder="City"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />

                        <input
                          name="state"
                          type="text"
                          className=""
                          placeholder="State/Province"
                          value={stateProvince}
                          onChange={(e) => setStateProvince(e.target.value)}
                        />

                        <input
                          name="zip"
                          type="text"
                          className=""
                          placeholder="Zip/Postal code"
                          value={zipPostalCode}
                          onChange={(e) => setZipPostalCode(e.target.value)}
                        />

                        <input
                          name="country"
                          type="text"
                          className=""
                          placeholder="Country"
                          value={shippingto}
                          readOnly={true}
                        />

                        <input
                          name="telephone"
                          type="text"
                          className=""
                          placeholder="Telephone"
                          value={telephoneNumber}
                          onChange={(e) => setTelephoneNumber(e.target.value)}
                        />

                        <textarea
                          name="message"
                          className=""
                          rows="3"
                          placeholder="Delivery information"
                        ></textarea>

                        {emailRegex.test(email) && (
                          <div className="mb-6">
                            <a
                              href="#"
                              onClick={() => handlePayCart({})}
                              className="btn btn-l btn-gradient dropbtn"
                            >
                              <span className="btn-border"></span>
                              <span className="btn-hover"></span>
                              <span className="btn-caption">Pay with Card</span>
                            </a>
                          </div>
                        )}
                      </form>
                    </div>
                  </div> */}

                  {showCheckout && isMobile && (
                    <Elements stripe={stripePromise} options={options}>
                      <CheckoutForm newOrderId={newOrderId} />
                    </Elements>
                  )}
                </div>
              )}
            </div>
          </div>

          <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
        </div>
      </section>
    </>
  );
};

export default Checkout;
