import React, { useEffect, useState } from "react";
import { db2 } from "../database/Firebase";

import {
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  LinkAuthenticationElement,
  PaymentElement,
  AddressElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

export default function CheckoutForm({newOrderId}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [address, setAddressState] = useState(null);

  useEffect(() => {
    //console.log('update db with address for this payment intent')
    //console.log('address:', address)

    const ref = doc(
        db2,
        `users/${process.env.REACT_APP_SITEID}/orders`,
        newOrderId
      );
      updateDoc(ref, {
        address,
        email
      });


  }, [address, email, newOrderId]);


  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          //setAddressState(() => [...address, clientSecret])
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "http://localhost:3000",
        return_url: "https://thisisobah.com/success",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <h3>Contact info</h3>
      <LinkAuthenticationElement
      // Access the email value like so:
      onChange={(event) => {
       setEmail(event.value.email);
      }}
      //
      // Prefill the email field like so:
      // options={{defaultValues: {email: 'foo@bar.com'}}}
      />

      <h3>Shipping address</h3>
      <AddressElement
        options={{ mode: "shipping", allowedCountries: ["US", "GB", "CA", "OM"] }}

        // Access the address like so:
        onChange={(event) => {
          setAddressState(event.value);
        }}
      />

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="btn btn-l btn-gradient paynow"
      >
        <span className="btn-border"></span>
        <span className="btn-hover"></span>
        <span className="btn-caption" id="button-text">
          {isLoading ? (
            <div className="payspinner" id="spinner"></div>
          ) : (
            "Pay now"
          )}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
