import logo from './logo.svg';
import './App.scss';
import Checkout from "./components/Checkout";

function App() {
  return (
    <>
      <Checkout />
    </>
  );
}

export default App;
