import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import r2wc from '@r2wc/react-to-web-component';
import Checkout from "./components/Checkout";

const wcCheckout = r2wc(Checkout, { props: { cartdata: "json" } });

customElements.define("r2w-wccheckout", wcCheckout);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
